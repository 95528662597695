import { AddBox, Delete, Edit, Visibility } from "@mui/icons-material";
import { Dialog, DialogContent } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import AdminAddUpdateDonor from "./AdminAddUpdateDonor";
import AdminDonorViewInformation from "./AdminDonorViewInformation";

export default function AdminHowsDonorList() {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [objItem, setObjItem] = useState({});

  const deleteOperation = useMutation(async (donorID) => {
    return await apiService.removeAdminDonor(donorID);
  });
  const q = useQuery(["adminAllDonorList"], async () => {
    return await apiService.getAdminDonorList();
  });

  const onComplete = () => {
    q.refetch();
  };

  const toggle = () => {
    setIsEdit(false);
    setIsOpen(false);
    setIsView(false);
    setObjItem({});
  };

  const deleteDonor = async (donorID) => {
    try {
      const isValid = await utils.showWarning("Do you want to delete!");
      if (isValid.isConfirmed) {
        const res = await deleteOperation.mutateAsync(donorID);
        if (res.success) {
          toast.success("Records has been delete.");
          onComplete();
          toggle();
          return null;
        }
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <>
      <DataTable
        title="Donors"
        isLoading={deleteOperation.isLoading || q.isLoading || q.isRefetching}
        isError={q.isError}
        errorMessage={
          q.isError
            ? (q.error && q.error?.error && q.error?.error?.message) ||
              "Errors occurred while getting donors list! please contact admin."
            : null
        }
        columns={[
          {
            title: "Created Date",
            field: "createdAt",
            render: (rowData) => {
              return format(new Date(rowData.createdAt), "dd/MM/yyyy");
            },
          },
          { title: "First Name", field: "userInfo.first_name" },
          { title: "Last Name", field: "userInfo.last_name" },
          { title: "Contact No", field: "userInfo.mobile" },
          { title: "Email", field: "userInfo.email" },
        ]}
        data={(q.data && q.data?.data && q.data?.data?.rows) || []}
        onReload={() => {
          q.refetch();
        }}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add Donor",
            isFreeAction: true,
            onClick: () => {
              setIsOpen(true);
            },
          },
          {
            icon: () => {
              return <Visibility color="info" />;
            },
            tooltip: "View More",
            onClick: (e, rowData) => {
              setIsView(true);
              setObjItem(rowData);
            },
          },
          {
            icon: () => {
              return <Edit color="primary" />;
            },
            tooltip: "Edit Information",
            onClick: (e, rowData) => {
              setIsEdit(true);
              setIsOpen(true);
              setObjItem(rowData);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Donor",
            onClick: (e, rowData) => {
              deleteDonor(rowData.id);
            },
          },
        ]}
      />

      {isView && (
        <Dialog
          open={isView}
          onClose={() => {
            setIsView(false);
            setObjItem({});
          }}
          maxWidth={"sm"}
          fullWidth
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AdminDonorViewInformation objItem={objItem} />
          </DialogContent>
        </Dialog>
      )}

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          fullWidth
          maxWidth="lg"
          sx={{
            display: "block",
            margin: "auto",
            maxWidth: "lg",
            overflow: "hidden",
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AdminAddUpdateDonor
              objItem={objItem}
              toggle={toggle}
              onComplete={onComplete}
              isEdit={isEdit}
              title={isEdit ? "Update Donor" : "Add Donor"}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* {isParentHowView && (
          <Dialog
            open={isParentHowView}
            onClose={() => {
              setIsParentHowView(false);
              setParentID(null);
            }}
            maxWidth={"sm"}
            fullWidth
            sx={{
              position: "absolute",
              zIndex: 12000,
            }}
          >
            <DialogContent>
              <ViewAdminHowParents parentID={parentID} />
            </DialogContent>
          </Dialog>
        )}
  
        {isView && (
          <Dialog
            open={isView}
            onClose={() => {
              setIsView(false);
              setObjItem({});
            }}
            maxWidth={"sm"}
            fullWidth
            sx={{
              position: "absolute",
              zIndex: 12000,
            }}
          >
            <DialogContent>
              <AdminHowDonorViewInformation objItem={objItem} />
            </DialogContent>
          </Dialog>
        )}
  
        {isAdding && (
          <Dialog
            open={isAdding}
            onClose={toggle}
            fullWidth
            sx={{
              display: "block",
              margin: "auto",
              maxWidth: "lg",
              overflow: "hidden",
              position: "absolute",
              zIndex: 12000,
            }}
          >
            <DialogContent>
              <AddUpdateDonor
                objItem={objItem}
                onComplete={onComplete}
                toggle={toggle}
                isEdit={isEdit}
                isAdmin={true}
              />
            </DialogContent>
          </Dialog>
        )} */}
    </>
  );
}
