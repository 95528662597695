import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Copyright } from "@mui/icons-material";
import { Button, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import NonAuthWrapper from "layout/MainLayout/NonAuthWrapper";
import { useEffect, useState } from "react";
import { redirect } from "react-router";
import { Link } from "react-router-dom";
import Logo from "./assets/HolyDomus-Logo-3000-800-T.png";


export default function LandingPage() {
  const theme = createTheme();
  const [jwt, setJWT] = useState(() => localStorage.getItem('jwt'))

  useEffect(() => {
    if (jwt !== null) {
      return redirect('/dashboard')
    }
  }, [jwt])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <NonAuthWrapper>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              // backgroundImage: 'url(https://source.unsplash.com/random)',
              backgroundImage: 'url(https://images.unsplash.com/photo-1676208972443-f6ac603af62c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3NzU2NzExOA&ixlib=rb-4.0.3&q=80&w=1080)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >

              <Typography component="h1" variant="h4">
                <img height={80} src={Logo} />
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                {/* <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
                {/* <Link
                
                to="/admin"            
                > */}
                {/* <Button 
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                <Link to="/admin">                  
                    Admin Login
                </Link>
                </Button> */}
                <Link to="/admin-login">
                  <Button fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }} >
                    Admin Login
                  </Button>
                </Link>
                <Link to="/donor-login">
                  <Button fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }} >
                    Donor Login
                  </Button>
                </Link>
                <Link to="/how-login">
                  <Button fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }} >
                    Holy House Login
                  </Button>
                </Link>
                {/* </Link> */}
                {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </NonAuthWrapper>
  );
}
