import { Box, Grid, Typography } from "@mui/material";
import utils from "services/Utils";

export default function ViewHowDonorInformation(props) {
  const userInfo = props.objHowDonor && props.objHowDonor.User;

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={"bold"} variant="h6">
            How Donor Information
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"} variant="body1">
            Created Date :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {utils.getDDMMYYYY(userInfo.createdAt) || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"} variant="body1">
            First Name :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {userInfo.first_name || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Last Name :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{userInfo.last_name || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Email Add :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{userInfo.email || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Contact No :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{userInfo.mobile || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Status :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {userInfo.isActive ? "Active" : "Not-Active" || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
