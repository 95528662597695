import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CommonStore } from "services/store/CommonStore/PullStore";

const DonorHolyHouseWidget = () => {
  const howImg = `https://holypenniesapi.herokuapp.com/images/howavatar.jpg`
  const isThemeMode = CommonStore.useState((s) => s.themeMode);
  const userData = [];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <img
            alt="complex"
            style={{
              width: "100%",
              height: "100%",
            }}
            src={howImg}
          />
        </Grid>
        <Grid item sm={6} xs={12} container spacing={6}>
          <Grid
            item
            xs
            container
            direction="column"
            spacing={2}
            sx={{
              paddingLeft: "67px",
            }}
            className="grid"
          >
            <Grid item xs spacing={2}>
              <Typography
                gutterBottom
                component="div"
                sx={{ mt: 4, color: isThemeMode ? "white" : "black" }}
              >
                <b>House of Worship Name :</b>
                {userData?.rows?.[0].How?.how_name || "Valleydale Church"}
              </Typography>
              <br />

              <Typography
                gutterBottom
                component="div"
                sx={{ color: isThemeMode ? "white" : "black" }}
              >
                <b>House of Worship Description : </b>{" "}
                {userData?.rows?.[0].How?.how_desc || "Valleydale Church"}
              </Typography>
              <br />
              <Typography
                gutterBottom
                component="div"
                sx={{ color: isThemeMode ? "white" : "black" }}
              >
                <b>House of Worship Address :</b>{" "}
                {userData?.rows?.[0].How?.address ||
                  "2324 Valleydale Rd, Birmingham, AL 35244, United States"}
              </Typography>
              <br />

              <Typography
                gutterBottom
                component="div"
                sx={{ color: isThemeMode ? "white" : "black" }}
              >
                <b>House of Worship State : </b>{" "}
                {userData?.rows?.[0].How?.state || "AL"}
              </Typography>
              <br />
              <Typography
                gutterBottom
                component="div"
                sx={{ color: isThemeMode ? "white" : "black" }}
              >
                <b>House of Worship City :</b>{" "}
                {userData?.rows?.[0].How?.city || "Birmingham"}
              </Typography>
              <br />

              <Typography
                gutterBottom
                component="div"
                sx={{ color: isThemeMode ? "white" : "black" }}
              >
                <b>House of Worship Country : </b>{" "}
                {userData?.rows?.[0].How?.country_code || "USA"}
              </Typography>
              <br />
              <Typography
                gutterBottom
                component="div"
                sx={{ color: isThemeMode ? "white" : "black" }}
              >
                <b>Status : </b>
                <span className="active  btn btn-success"> Active</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default DonorHolyHouseWidget;
