import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import utils from "services/Utils";
import "../../../assets/scss/updateGoal.scss";

const DonorSetupGoal = (props) => {
  const [amount, setAmount] = useState(1);
  const [custom, setCustom] = useState(false);
  const [type, setType] = useState("roundup");
  const userData = useSelector((state) => state.DonorReducer);
  const [tabPannelValue, settabPannelValue] = useState(0);
  const [amountIndex, setamountIndex] = useState(null);
  const amountList = [1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0];

  const changeTabpannel = (event, newValue) => {
    settabPannelValue(newValue);
    setCustom(false);
    setamountIndex(null);
    if (newValue === 0) {
      setType("roundup");
    } else {
      setType("fixed");
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
    }
    return () => {
      mounted = false;
    };
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      className: `tabs`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const setCustomAmountState = (e) => {
    setType(e.target.name);

    setAmount(e.target.value);
    setCustom(true);
    setamountIndex(null);
  };

  const selectAmountBox = (amountIndex) => {
    setCustom(false);
    let amoount = amountList[amountIndex];
    setamountIndex(amountIndex);
    setAmount(amoount);
  };

  function HandelUpdateGoal() {
    const data = { amount: amount, isdailymax: false, isdailyfixed: false };
    let response = null;

    if (type === "fixed") {
      data.isdailyfixed = true;
      if (response) {
        userData.isdailyfixed = data.isdailyfixed;
        userData.isdailymax = data.isdailymax;

        userData.amount = amount;
        localStorage.setItem("user", JSON.stringify(userData));
      }
    } else if (type === "roundup") {
      data.isdailymax = true;
      if (response) {
        userData.isdailyfixed = data.isdailyfixed;
        userData.isdailymax = data.isdailymax;
        userData.amount = amount;
        localStorage.setItem("user", JSON.stringify(userData));
      }
    } else {
      toast.error("Select Correct Option");
    }
  }

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Box
            sx={{
              margin: "auto",
              minWidth: "100%",
              maxWidth: "100%",
              height: "85%",
            }}
          >
            <Box>
              <Tabs
                value={tabPannelValue}
                onChange={changeTabpannel}
                aria-label="basic tabs example"
              >
                <Tab label="Daily Round up" {...a11yProps(0)} />
                <Tab label="Daily Fixed" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabPannelValue} index={0}>
              <Typography color="textPrimary" variant="h6">
                Choose A Daily Round Up Amount To Donate:{" "}
              </Typography>
              <Box
                sx={{
                  margin: "20px",
                  display: "grid",
                  gridTemplateColumns: " repeat(3, 1fr)",
                  maxHeight: "500px",
                  // margin: " 0px",
                  gridGap: "1rem",
                }}
              >
                {_.map(amountList, (prideData, i) => (
                  <Box
                    key={`${i}-roundup`}
                    onClick={() => selectAmountBox(i)}
                    sx={{
                      maxWidth: "140px",
                      borderRadius: "5px",
                      border: "1px solid skyblue",
                      cursor: "pointer",
                      position: "relative",
                      padding: 1,
                      transition: "0.5s all",
                      // "&:hover": {
                      //   backgroundColor: "rgb(217 220 240)",
                      // },
                    }}
                    className={` ${
                      amountIndex === i && type === "roundup" && `active-goal`
                    }`}
                  >
                    {amountIndex === i && type === "roundup" && (
                      <Box
                        sx={{
                          position: "absolute",
                          right: "1px",
                          top: "1px",
                        }}
                      >
                        <CheckCircle color="primary" />
                      </Box>
                    )}
                    <Box
                      SX={{
                        height: "2.5vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "& > ": {
                          fontWeight: "bold",
                          fontSize: "0.8em",
                        },
                      }}
                    >
                      <Typography variant="body1">
                        {utils.prettyPrice(Number(prideData))}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-number"
                    sx={{
                      color: "inherit",
                    }}
                    label="Amount"
                    type="number"
                    variant="filled"
                    name="roundup"
                    placeholder="Custom Amount"
                    value={type === "roundup" && custom && amount}
                    onChange={setCustomAmountState}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={HandelUpdateGoal}
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    Update Goal
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabPannelValue} index={1}>
              <Typography color="textPrimary" variant="h6">
                Choose A Daily Fixed Amount To Donate:{" "}
              </Typography>

              <Box className="priceBox__container">
                {_.map(amountList, (prideData, i) => (
                  <Box
                    key={`${i}-fixed`}
                    onClick={() => selectAmountBox(i)}
                    className={`priceBox__wrappper ${
                      amountIndex === i && type === "fixed" && "active-goal"
                    }`}
                  >
                    {amountIndex === i && type === "fixed" ? (
                      <Box className="checkicon">
                        <CheckCircle color="primary" />
                      </Box>
                    ) : null}
                    <Box className="price___item text-center">
                      <Typography variant="body1">
                        {utils.prettyPrice(Number(prideData))}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Grid container spacing={1} pt={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-number"
                    label="Amount"
                    type="number"
                    variant="filled"
                    name="fixed"
                    placeholder="Custom Amount"
                    value={type === "fixed" && custom && amount}
                    onChange={setCustomAmountState}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={HandelUpdateGoal}
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DonorSetupGoal;
