import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import GetRequiredLabel from "Common/GetRequiredLabel";
import HRoundedTextField from "Common/Inputs/HRoundedTextField";
import { RedditTextField } from "Common/Inputs/Inputs";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import apiService from "services/http-service/ApiService";
import authService from "services/http-service/AuthService";
import { CommonStore } from "services/store/CommonStore/PullStore";
import * as yup from "yup";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  isEdit: yup.boolean(),
  password: yup.string().when("isEdit", {
    is: true,
    then: yup.string().optional(),
    otherwise: yup.string().required("Password is required"),
  }),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Email is required"),
  mobile: yup.string().min(10).max(10).required("Phone number is required"),
});

export default function AddUpdateDonor(props) {
  const objItem = props.objItem
  const [showPassword, setShowPassword] = useState(false);
  const isWhiteTheme = CommonStore.useState((s) => s.themeMode);
  const navigate = useNavigate();

  const addOperation = useMutation(async (data) => {
    if (props.isAdmin) {
      return await apiService.createAdminHowDonor(data);
    } else {
      return await apiService.createHowDonor(data);
    }
  });

  const updateOperation = useMutation(async (data) => {
    if (props.isAdmin) {
      return await apiService.updateAdminHowDonor(data);
    } else {
      return await apiService.updateHowDonor(data);
    }
  });

  const objForm = useForm({
    defaultValues: {
      firstName: objItem?.User?.first_name || "",
      lastName: objItem?.User?.last_name || "",
      email: objItem?.User?.email || "",
      password: "",
      isEdit: props.isEdit,
      mobile: objItem?.User?.mobile || "",
    },
    resolver: yupResolver(schema),
  });

  const onSave = async (data) => {
    try {
      if (!props.isEdit) {
        const io = {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          mobile: data.mobile,
          password: data.password,
        };
        if (props.isAdmin) {
          io.howID = null;
        } else {
          io.howID = authService.getUserData().id;
        }

        const result = await addOperation.mutateAsync(io);
        if (result.success) {
          toast.success("User has been created successfully.");
          if (props.onComplete) {
            props.onComplete();
          }
          if (props.toggle) {
            props.toggle();
          }

          return null;
        }
      } else {
        const io = {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          mobile: data.mobile,
          id: objItem?.id,
        };

        const result = await updateOperation.mutateAsync(io);
        if (result.success) {
          toast.success("User has been updated successfully.");
          if (props.onComplete) {
            props.onComplete();
          }
          if (props.toggle) {
            props.toggle();
          }

          return null;
        }
      }

      throw new Error(
        "Error occurred while updating the how donor, try again!"
      );
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        fontWeight={"bold"}
        color={isWhiteTheme ? "white" : "black"}
      >
        {props.isEdit ? "Update How Donor" : "Add How Donor"}
        <Divider />
      </Typography>
      <FormProvider {...objForm}>
        <form onSubmit={objForm.handleSubmit(onSave)}>
          <Box sx={{ maxWidth: "100%", py: 2 }}>
            <Grid container spacing={2} pb={0}>
              <Grid item xs={12} md={props.isEdit ? 6 : 4}>
                <GetRequiredLabel isRequired={true} name={"First Name"} />
                <HRoundedTextField name={"firstName"} label={"First Name"} />
              </Grid>
              <Grid item xs={12} md={props.isEdit ? 6 : 4}>
                <GetRequiredLabel name={"Last Name"} isRequired={true} />
                <HRoundedTextField name={"lastName"} label={"Last Name"} />
              </Grid>
              <Grid item xs={12} md={props.isEdit ? 6 : 4}>
                <GetRequiredLabel isRequired={true} name={"Mobile"} />
                <HRoundedTextField name={"mobile"} label={"Mobile"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetRequiredLabel isRequired={true} name={"Email"} />
                <HRoundedTextField name={"email"} label={"Email"} />
              </Grid>
              {!props.isEdit && (
                <Grid item xs={12} md={6}>
                  <GetRequiredLabel isRequired={true} name={"Password"} />
                  <Controller
                    name={"password"}
                    control={objForm.control}
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <RedditTextField
                          fullWidth
                          variant="outlined"
                          label="Password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                          error={Boolean(objForm.formState.errors.password)}
                          helperText={
                            objForm.formState.errors.password
                              ? objForm.formState.errors.password.message
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
              {addOperation.isError && (
                <Grid item xs={12} py={2}>
                  <Box py={2}>
                    <ErrorPlaceHolder
                      error={
                        addOperation.error &&
                        addOperation.error.error &&
                        addOperation.error.error.message &&
                        addOperation.error.error.message.details &&
                        addOperation.error.error.message.details[0] &&
                        addOperation.error.error.message.details[0].message
                          ? addOperation.error.error.message.details[0].message
                          : addOperation.error &&
                            addOperation.error.data &&
                            addOperation.error.data.error
                          ? addOperation.error.data.error.message
                          : addOperation.error.error.message
                      }
                    />
                  </Box>
                </Grid>
              )}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: "100%",
                  py: 2,
                }}
                justifyContent={"center"}
              >
                <LoadingButton
                  loading={addOperation.isLoading || updateOperation.isLoading}
                  disabled={addOperation.isLoading || updateOperation.isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {props.isEdit ? "Update" : "Save"}
                </LoadingButton>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (props.toggle) {
                      props.toggle();
                    } else {
                      navigate("/");
                    }
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
