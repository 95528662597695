import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IMAGE_FORMAT } from "services/DefaultConfig";
import apiService from "services/http-service/ApiService";
import { setIsCreateNewEvent } from "services/store/ApiStates/ApiCommonStore";
import * as yup from "yup";

export default function AddUpdateEvent(props) {
  const objItem = props.objItem
  const schema = yup.object().shape({
    eventTitle: yup
      .string()
      .min(6, "Min 6 characters are required")
      .required("Event name is required"),
    description: yup
      .string()
      .min(15, "Min 15 characters are required")
      .required("Event description is required"),
    dateTime: props.isEdit
      ? yup.date().optional()
      : yup.date().min(new Date()).required("Date & Time is required"),
    file: yup
      .mixed()
      .optional()
      .test("fileType", "Unsupported File Format", function (value) {
        return value?.type ? IMAGE_FORMAT.includes(value?.type) : true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const sizeInBytes = 5000000; //0.5MB
        return value?.size ? value?.size <= sizeInBytes : true;
      }),
  });
  const objForm = useForm({
    defaultValues: {
      eventTitle: objItem?.title || "",
      description: objItem?.desc || "",
      dateTime: objItem?.createdAt || "",
      file: objItem?.image || "",
    },
    resolver: yupResolver(schema),
  });

  const createEventAction = useMutation(async (data) => {
    if (props.isAdmin) {
      return await apiService.createAdminEvent(data);
    } else {
      return await apiService.createHowEvent(data);
    }
  });

  const updateAction = useMutation(async (data) => {
    if (props.isAdmin) {
      return await apiService.updateEvent(data);
    } else {
      // return await apiService.createHowEvent(data);
    }
  });

  const onSave = async (eventData) => {
    const expireIn = Math.round(new Date(eventData.dateTime));

    const io = {
      title: eventData.eventTitle,
      desc: eventData.description,
      dateTime: props.isEdit
        ? objItem.createdAt
        : new Date(eventData.dateTime).toISOString(),
      expireIn: objItem?.expireIn || expireIn,
    };

    if (props.isEdit) {
      io.eventid = objItem.eventid;
    }

    // if (eventData.file) io.file = eventData.file;

    if (props.isEdit) {
      const response = await updateAction.mutateAsync(io);
      if (response.success) {
        if (props.toggle) {
          props.toggle();
        }
        if (props.onComplete) {
          props.onComplete();
        } else {
          setIsCreateNewEvent(true);
        }

        toast.success("Event has been updated");
        objForm.reset();
      }
    } else {
      const response = await createEventAction.mutateAsync(io);
      if (response.success) {
        if (props.toggle) {
          props.toggle();
        }
        if (props.onComplete) {
          props.onComplete();
        } else {
          setIsCreateNewEvent(true);
          objForm.reset();
        }
        toast.success("Event has been created");
      }
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h5" color="inherit">
        Create Event
      </Typography>

      <form onSubmit={objForm.handleSubmit(onSave)}>
        <Box sx={{ maxWidth: "100%", mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="eventTitle"
                type="text"
                label="Event Title"
                error={Boolean(objForm.formState.errors.eventTitle)}
                helperText={
                  Boolean(objForm.formState.errors.eventTitle)
                    ? objForm.formState.errors.eventTitle.message
                    : ""
                }
                {...objForm.register("eventTitle")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                name="description"
                type="text"
                {...objForm.register("description")}
                error={Boolean(objForm.formState.errors.description)}
                helperText={
                  Boolean(objForm.formState.errors.description)
                    ? objForm.formState.errors.description.message
                    : ""
                }
              />
            </Grid>
            {!props.isEdit && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="dateTime"
                  id="datetime-local"
                  label="Date & Time"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...objForm.register("dateTime")}
                  error={Boolean(objForm.formState.errors.dateTime)}
                  helperText={
                    Boolean(objForm.formState.errors.dateTime)
                      ? objForm.formState.errors.dateTime.message
                      : ""
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                type="file"
                name="file"
                fullWidth
                label="Select image for event"
                onChange={(event) =>
                  // setFieldValue("file", event.currentTarget.files[0])
                  console.log(event)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                {...objForm.register("file")}
                error={Boolean(objForm.formState.errors.file)}
                helperText={
                  Boolean(objForm.formState.errors.file)
                    ? objForm.formState.errors.file.message
                    : ""
                }
              />
            </Grid>
          </Grid>
          {createEventAction.isError && (
            <Box py={2}>
              <ErrorPlaceHolder
                error={createEventAction.error?.error?.message}
              />
            </Box>
          )}
          <Stack sx={{ mt: 2 }} spacing={2} direction="row">
            <LoadingButton
              loading={createEventAction.isLoading || updateAction.isLoading}
              disabled={createEventAction.isLoading || updateAction.isLoading}
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </LoadingButton>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                if (props.toggle) {
                  props.toggle();
                } else {
                  objForm.reset();
                }
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
}
