import * as Yup from "yup";
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Password must be 3 characters at minimum")
    .required("Password is required"),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const UpdateProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(6, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("First name is required"),
  last_name: Yup.string()
    .min(6, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email Address Required"),
  mobile: Yup.string()
    .required("Mobile number required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(10, "to long"),
  how_name: Yup.string().required("address is required"),
  how_desc: Yup.string().required("address is required"),
  address: Yup.string().required("address is required"),
  country_code: Yup.string().required("country is required"),
  state: Yup.string().required("state is required"),
  city: Yup.string().required("city is required"),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  confirmPassword: Yup.string()
    .required("This field is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "password need to be the same"
      ),
    }),
});

export const createEventSchema = Yup.object({
  title: Yup.string()
    .min(6, "Min 6 characters are required")
    .required("Event name is required"),
  desc: Yup.string()
    .min(15, "Min 15 characters are required")
    .required("Event description is required"),
  dateTime: Yup.date()
    .min(new Date(Date.now()))
    .required("Date & Time is required"),
  file: Yup.mixed()
  .optional()
  .test("fileType", "Unsupported File Format", function (value) {
    return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
  })
  .test("fileSize", "File Size is too large", (value) => {
    const sizeInBytes = 5000000; //0.5MB
    return value?.size ? value?.size <= sizeInBytes : true;
  }),
});

export const UpdateEventSchema = Yup.object({
  title: Yup.string()
    .min(6, "Min 6 characters are required")
    .required("Event name is required"),
  desc: Yup.string()
    .min(15, "Min 15 characters are required")
    .required("Event description is required"),
  dateTime: Yup.date()
    .min(new Date(Date.now()))
    .required("Date & Time is required"),
  file: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

const passwordRegExp =
/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const Howschema = Yup.object().shape({
  first_name: Yup.string().max(255).required("Firstname is required"),
  last_name: Yup.string().max(255).required("Lastname is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string()
    .max(255)
    .required("Password is required")
    .matches(
      passwordRegExp,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),

  mobile: Yup.string("Enter your Mobile number")
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required")
    .min(10, "Number Must be 10 digit only!")
    .max(10, "Number Must be 10 digit only!"),
  image: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  how_name: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("House name is required"),
  address: Yup.string()
    .min(12, "Minimum character is 12")
    .required("Address is required"),

  how_desc: Yup.string()
    .min(15, "Minimum character is 15")
    .required("Description is required"),

  country_code: Yup.string().required("Country is required"),

  state: Yup.string().required("State is required"),

  city: Yup.string().required("City is required"),

  zipcode: Yup.string()
    .required("Zip code is required")
    .min(5, "Minimum character is 5"),

  how_image: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),

  backimage: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export const Loginschema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});

export const ForgotPasswordchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

//child How
export const ChildHowschema = Yup.object().shape({
  how_name: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("Child House name is required"),
  address: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("Address is required"),

  how_desc: Yup.string()
    .min(15, "Minimum character is 15")
    .required("Description is required"),

  country_code: Yup.string().required("Country is required"),

  state: Yup.string().required("State is required"),

  city: Yup.string().required("City is required"),

  zipcode: Yup.string() 
    .required("Zip Code is required")
    .min(5, "Minimum character is 5"),

  how_image: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),

  backimage: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export const UpdateChildHowschema =Yup.object().shape({
  first_name: Yup.string().max(255).required("Firstname is required"),
  last_name: Yup.string().max(255).required("Lastname is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  mobile: Yup.string("Enter your Mobile number")
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required")
    .min(10, "Number Must be 10 digit only!")
    .max(10, "Number Must be 10 digit only!"),
  image: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  
  how_name: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("Child House name is required"),

  address: Yup.string()
    .min(12, "Minimum character is 12")
    .required("Address is required"),

  how_desc: Yup.string()
    .min(15, "Minimum character is 15")
    .required("Description is required"),

  country_code: Yup.string().required("Country is required"),

  state: Yup.string().required("State is required"),

  city: Yup.string().required("City is required"),

  zipcode: Yup.string()
    .required("Zip Code is required")
    .min(5, "Minimum character is 5"),

  how_image: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),

  backimage: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export const UpdateUserProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email Address Required"),
  mobile: Yup.string()
    .required("Mobile number required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(10, "to long"),
});

export const UpdateHowProfileSchema = Yup.object().shape({
  how_name: Yup.string()
    .min(2, "Minimum character is 2")
    .max(50, "Maximum character is 50.")
    .required("House name is required"),
  address: Yup.string()
    .min(12, "Minimum character is 12")
    .required("Address is required"),

  how_desc: Yup.string()
    .min(15, "Minimum character is 15")
    .required("Description is required"),

  country_code: Yup.string().required("Country is required"),

  state: Yup.string().required("State is required"),

  city: Yup.string().required("City is required"),

  zipcode: Yup.string()
    .required("Zip code is required")
    .min(5, "Minimum character is 5"),

  how_image: Yup.mixed()
            .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),

  backimage: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export const childHowInviteSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  firstName: Yup.string().required("First Name is required"),
});

export const addChildHouseSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  childHouseName: Yup.string().required("Child house name is required"),
  email: Yup.string()
    .email("Invalid Email Address")
    .required("First name is required"),
  password: Yup.string().required("Password is required"),
  mobile: Yup.string().min(10).max(10).required("Phone number is required"),
  address: Yup.string().required("Address field is required"),
  description: Yup.string().required("Description is required"),
  country: Yup.string().required("Country is  required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  zipCode: Yup.string().required("Zip code is required"),
  userProfile: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  howLogo: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  howImage: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export const createDonorSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid Email Address")
    .required("First name is required"),
  password: Yup.string().required("Password is required"),
  mobile: Yup.string().min(10).max(10).required("Phone number is required"),
  profileImage: Yup.mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});
