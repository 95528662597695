import { Box, Card } from "@mui/material";
import { useState } from "react";
import { CommonStore } from "services/store/CommonStore/PullStore";
import {
  handleCardClose
} from "services/store/GridTemplateLayoutStore/HowDashboardLayoutStore";
import CardTable from "./DataTable/CardTable";
import MaxWindow from "./MaxWindow";
const GetWindowButton = ({
  children,
  isCard,
  title,
  isDonationCard,
  overFlowNone,
  cardID,
  isVisible,
}) => {
  const themeMode = CommonStore.useState((s) => s.themeMode);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  if (isOpen) {
    return (
      <MaxWindow isOpen={isOpen} toggle={toggle}>
        {children}
      </MaxWindow>
    );
  }

  if (isDonationCard) {
    return (
      <Card
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            px: "0.8rem",
            pt: "0.5rem",
            backgroundColor: "transparent",
            width: "100%",
            position: "relative",
            top: 0,
            right: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              toggle();
            }}
          >
            <img
              src="/icons/arrows.png"
              alt="arrowsBlack.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box>
          {/* <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleMinimize(cardID);
            }}
          >
            <img
              src="/icons/minus.png"
              alt="minusBlack.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box> */}
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleCardClose(cardID);
            }}
          >
            <img
              src="/icons/cross.png"
              alt="cross.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box>
        </Box>
        {isVisible && children}
      </Card>
    );
  }

  if (isCard) {
    return (
      <Card
        className="data-table"
        style={{
          height: "100%",
          padding: "1rem",
          overflow: overFlowNone ? "none" : "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            px: "0.8rem",
            pt: "0.5rem",
            backgroundColor: "transparent",
            width: "100%",
            position: "relative",
            top: 0,
            right: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              toggle();
            }}
          >
            <img
              src="/icons/arrows.png"
              alt="arrowsBlack.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box>
          {/* <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleMinimize(cardID);
            }}
          >
            <img
              src="/icons/minus.png"
              alt="minusBlack.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box> */}
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleCardClose(cardID);
            }}
          >
            <img
              src="/icons/cross.png"
              alt="cross.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box>
        </Box>
        {isVisible && children}
      </Card>
    );
  }

  if (!isCard) {
    return (
      <CardTable>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            px: "0.8rem",
            pt: "0.5rem",
            backgroundColor: "transparent",
            width: "100%",
            position: "relative",
            top: 0,
            right: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              toggle();
            }}
          >
            <img
              src="/icons/arrows.png"
              alt="arrowsBlack.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box>
          {/* <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleMinimize(cardID);
            }}
          >
            <img
              src="/icons/minus.png"
              alt="minusBlack.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box> */}
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleCardClose(cardID);
            }}
          >
            <img
              src="/icons/cross.png"
              alt="cross.png"
              height={"18px"}
              width={"18px"}
              style={{
                cursor: "pointer !important",
                marginLeft: "2%",
                filter: themeMode ? "brightness(0) invert(1)" : "none",
              }}
            />
          </Box>
        </Box>
        {isVisible && children}
      </CardTable>
    );
  }
};

export default GetWindowButton;
