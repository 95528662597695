import { AddBox, Delete, Edit, Visibility } from "@mui/icons-material";
import { Dialog, DialogContent } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import { ApiCommonStore } from "services/store/ApiStates/ApiCommonStore";
import AdminHowAddUpdate from "../How/AdminHowAddUpdate";
import ViewAdminUserInfo from "./ViewAdminUserInfo";

export default function AdminUserList() {
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [objItem, setObjItem] = useState({});

  const deleteAction = useMutation(async (adminID) => {
    return await apiService.removeAdminUser(adminID);
  });

  const isChildHowListChanged = ApiCommonStore.useState(
    (s) => s.isChildHowListChanged
  );

  const toggle = () => {
    setIsView(false);
    setIsAdding(false);
    setIsEdit(false);
  };

  const q = useQuery(
    ["getAllAdminUserList", isChildHowListChanged],
    async () => {
      return await apiService.getAdminUserList();
    }
  );

  const onComplete = () => {
    q.refetch();
  };

  const deleteAdminUser = async (adminID) => {
    try {
      const isValid = await utils.showWarning(
        "Do you want to delete this record?"
      );

      if (isValid.isConfirmed) {
        const result = await deleteAction.mutateAsync(adminID);
        if (!result.success) {
          throw new Error(result.error.message);
        }

        if (result.success) {
          toast.success(result.data);
          q.refetch();
        }
      }
    } catch (ex) {
      if (ex && ex.error && ex.error.message) {
        toast.error(ex.error.message);
      } else {
        toast.error(ex.message);
      }
    }
  };

  return (
    <>
      <DataTable
        title="Admin User List"
        isLoading={deleteAction.isLoading || q.isLoading || q.isRefetching}
        isError={q.isError}
        errorMessage={
          q.isError
            ? (q.error && q.error.error && q.error.error.message) ||
              "Errors occurred while getting hows list! please contact admin."
            : null
        }
        columns={[
          {
            title: "Created Date",
            field: "createdAt",
            render: (rowData) => {
              return format(new Date(rowData.createdAt), "dd/MM/yyyy");
            },
          },
          {
            title: "First Name",
            field: "first_name",
          },
          {
            title: "Last Name",
            field: "last_name",
          },
          { title: "Contact No.", field: "mobile" },
          { title: "Role", field: "role" },
        ]}
        data={(q.data && q.data.data) || []}
        onReload={() => {
          q.refetch();
        }}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add Admin",
            isFreeAction: true,
            onClick: () => {
              setIsEdit(false);
              setIsAdding(true);
              setObjItem({});
            },
          },
          {
            icon: () => {
              return <Visibility color="info" />;
            },
            tooltip: "View More",
            onClick: (e, rowData) => {
              setIsView(true);
              setObjItem(rowData);
            },
          },
          {
            icon: () => {
              return <Edit color="primary" />;
            },
            tooltip: "Edit Information",
            onClick: (event, rowData) => {
              setIsEdit(true);
              setIsAdding(true);
              setObjItem(rowData);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Hows",
            onClick: (e, rowData) => {
              deleteAdminUser(rowData?.howAdmin?.id);
            },
          },
        ]}
      />
      {isView && (
        <Dialog
          open={isView}
          onClose={() => {
            setIsView(false);
            setObjItem({});
          }}
          maxWidth={"sm"}
          fullWidth
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <ViewAdminUserInfo objItem={objItem} />
          </DialogContent>
        </Dialog>
      )}

      {isAdding && (
        <Dialog
          open={isAdding}
          onClose={toggle}
          maxWidth={"lg"}
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AdminHowAddUpdate
              isEdit={isEdit}
              objItem={objItem}
              onComplete={onComplete}
              toggle={toggle}
              isAdmin={true}
              title={isEdit ? "Update Admin User" : "Add Admin User"}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
