import { Settings } from "@material-ui/icons";
import { Laptop } from "@mui/icons-material";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { adminMenu } from "Common/NavigationMenu";
import { useNavigate } from "react-router";
import { CommonStore } from "services/store/CommonStore/PullStore";

export default function AdminSideBar(props) {
  const navigate = useNavigate();
  const isDrawerOpen = CommonStore.useState((s) => s.isDrawerOpen);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      sx={{
        "& > div": {
          marginTop: "50px",
          paddingTop: "20px",
          width: "220px !important",
          position: "fixed !important",
          zIndex: "1099 !important",
        },
      }}
    >
      <List sx={{ display: "flex", flexDirection: "column" }}>
        {adminMenu &&
          adminMenu.map((item, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => {
                  if (item.link === "/dashboard") {
                    navigate(`${item.link}`);
                  } else {
                    navigate(`/dashboard/${item.link}`);
                  }
                }}
              >
                <ListItem sx={{ padding: 0 }}>
                  <ListItemAvatar>{item.icon}</ListItemAvatar>
                  <ListItemText primary={item.name} />
                </ListItem>
              </ListItemButton>
            );
          })}

        <Divider />
        <ListItemButton
          onClick={() => {
            navigate("#");
          }}
        >
          <ListItem sx={{ padding: 0 }}>
            <ListItemAvatar>
              <Laptop />
            </ListItemAvatar>
            <ListItemText primary={"Device"} />
          </ListItem>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate("#");
          }}
        >
          <ListItem sx={{ padding: 0 }}>
            <ListItemAvatar>
              <Settings />
            </ListItemAvatar>
            <ListItemText primary={"Settings"} />
          </ListItem>
        </ListItemButton>
      </List>
      <Divider />
    </Drawer>
  );
}
