import { Box, Container, Typography } from "@mui/material";

export default function HowTermPolicy(props) {
  return (
    <>
      <Container>
        <Box py={2}>
          <Typography color={"inherit"} variant="h5">
            Privacy Policy
          </Typography>
        </Box>
        <Typography color={"inherit"} variant="body1">
          This Holly Pennies Platform Privacy Policy describes how we collects,
          uses, shares, and retains personal data that you provide to us, or
          that we collect, when you use the Holly Pennies platform accessible at
          www.HolyPennies.com , and the services, content and other materials
          made available through the Holly Pennies Platform. As used in this
          Privacy Policy, references to the Holly Pennies Platform shall be
          deemed to include all of the Holly Pennies Services.
        </Typography>
        <br />
        <Typography color={"inherit"} variant="body1">
          This Privacy Policy explains what Personal Data (defined below) we
          collect, how we use and share that data, and your choices concerning
          our data practices.
        </Typography>
      </Container>
      <Container maxWidth="lg">
        <ol>
          <Box py={1}>
            <li>
              <Typography color={"inherit"} variant="body1">
                Information We Collect from You through the Holly Pennies
                Platform
              </Typography>
            </li>
            <Typography color={"inherit"} variant="body1">
              Information You Voluntarily Provide through the Holly Pennies
              Platform. We collect data that could be used to identify you
              personally (“Personal Data”) from you when you create an account
              and use the Holly Pennies Platform. The Personal Data that we
              collect may vary based on who you are and what you choose to share
              with us. As part of the registration process, we collect each
              User’s first and last name, email address, mailing address, and
              phone number. If you are creator of content (a “Creator”), we will
              also collect any additional information about yourself that you
              choose to include as part of your User profile, which may include
              a profile photo, your industry expertise, your experience level,
              your education level, your desired pay rate, and the location(s)
              where you are available to provide services. If you are a studio,
              production company, agency or other content developer or
              distributor (a “Client”), we will also collect company logo, and
              URL.
            </Typography>
          </Box>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Information We Receive Automatically From Your Use of the Holly
                Pennies Platform. When you visit, use and interact with the
                Holly Pennies Platform, we may receive certain information about
                your visit, use or interactions. For example, we may monitor the
                number of people that visit the Holly Pennies Platform, peak
                hours of visits, which page(s) are visited on the Holly Pennies
                Platform, the domains our visitors come from (e.g., google.com,
                yahoo.com, etc.), and which browsers people use to access and
                visit the Holly Pennies Platform (e.g., Firefox, Microsoft
                Internet Explorer, etc.), broad geographical information, and
                website navigation patterns. In particular, the following
                information is created and automatically logged in our systems:
              </Typography>
              <ul>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Log data: Information that your browser automatically
                      sends whenever you visit the Holly Pennies Platform (“log
                      data”). Log data includes your Internet Protocol (“IP”)
                      address (so we understand where you are connecting from
                      when you visit the Holly Pennies Platform), browser type
                      and settings, the date and time of your request, and how
                      you interacted with the Holly Pennies Platform.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Cookies: Please see the “Cookies” section below to learn
                      more about how we use cookies.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Device information: We collect the name of the device,
                      operating system, and browser you are using. Information
                      collected may depend on the type of device you use and its
                      settings.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Usage Information: We collect information about how you
                      use the Holly Pennies Platform, such as the types of
                      content that you view or engage with, the features you
                      use, the actions you take, and the time, frequency and
                      duration of your activities.
                    </Typography>
                  </Box>
                </li>
              </ul>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Cookies and Other Tracking Technologies
              </Typography>
              <ul>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Our Use of Cookies. We use cookies to operate and
                      administer the Holly Pennies Platform, gather usage data
                      on the Holly Pennies Platform and improve your experience
                      using the Holly Pennies Platform. A “cookie” is a piece of
                      information sent to your browser by a website you visit.
                      Cookies can be stored on your computer for different
                      periods of time. Some cookies expire after a certain
                      amount of time, or upon logging out (session cookies),
                      others survive after your browser is closed until a
                      defined expiration date set in the cookie (as determined
                      by the third party placing it), and help recognize your
                      computer when you open your browser and browse the
                      Internet again (persistent cookies). For more details on
                      cookies please visit All About Cookies.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Analytics Providers. We use Google Analytics, a web
                      analytics service provided by Google, Inc. (“Google”).
                      Google Analytics uses cookies to help us analyze how users
                      use the Holly Pennies Platform and enhance your experience
                      when you use the Holly Pennies Platform. For more
                      information on how Google uses this data, go to
                      www.google.com/policies/privacy/partners/.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Online Tracking and Do Not Track Signals. We do not use
                      cookies, pixels or other tracking technologies to collect
                      information about your browsing activities over time and
                      across different websites following your use of the Holly
                      Pennies Platform. The Holly Pennies Platform currently
                      does not respond to “Do Not Track” (“DNT”) signals and
                      operates as described in this Privacy Policy whether or
                      not a DNT signal is received. If we do respond to DNT
                      signals in the future, we will update this Privacy Policy
                      to describe how we do so.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Your Choices. On most web browsers, you will find a “help”
                      section on the toolbar. Please refer to this section for
                      information on how to receive a notification when you are
                      receiving a new cookie and how to turn cookies off. Please
                      note that if you limit the ability of websites to set
                      cookies, you may be unable to access certain parts of the
                      Holly Pennies Platform and you may not be able to benefit
                      from the full functionality of the Holly Pennies Platform.
                      Most advertising networks offer you a way to opt out of
                      targeted advertising. If you would like to find out more
                      information, please visit the Network Advertising
                      Initiative’s online resources at
                      http://www.networkadvertising.org and follow the opt-out
                      instructions there. If you access the Holly Pennies
                      Platform on your mobile device, you may not be able to
                      control tracking technologies through the settings.
                    </Typography>
                  </Box>
                </li>
              </ul>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Information Collected by Third Parties Through the Holly Pennies
                Platform
              </Typography>
              <ul>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Information Collected by Third Parties through Third Party
                      Links and Content. The Holly Pennies Platform may include
                      links to other websites and other content from third party
                      businesses. These third party businesses may use cookies,
                      web beacons or other similar technology to collect
                      information about you. Holly Pennies does not have access
                      to or control over these third parties or the cookies, web
                      beacons or other technology that these third parties may
                      use. We are not responsible for the privacy practices of
                      these third parties or the content on any third party
                      website. You are encouraged to review the privacy policies
                      of the different websites you visit.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box py={1}>
                    <Typography color={"inherit"} variant="body1">
                      Payment Processors. The Holly Pennies Platform may allow
                      you to purchase services we offer for a fee. We rely on
                      third party service providers (“Payment Processors”) to
                      process these payments on our behalf. When you make a
                      payment, the payment information is transmitted directly
                      to the Payment Processor, and we do not have access to it,
                      and take no responsibility for it. However, our Payment
                      Processor may provide us with information about you, such
                      as your name, mailing address, phone number, email
                      address, card type (i.e., Visa, Amex, etc.) and the credit
                      card’s last four digits so we can comply with legal
                      obligations that may apply to us.
                    </Typography>
                  </Box>
                </li>
              </ul>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                How We Use Your Information. We use Personal Data for the
                following purposes:
              </Typography>
            </Box>
            <ul>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    To provide the Holly Pennies Platform, including to suggest
                    opportunities to Creators and to recommend Creators to
                    Client.
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    To respond to your inquiries, comments, feedback or
                    questions.
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    To send administrative information to you, for example,
                    information regarding the Holly Pennies Platform, and
                    changes to our terms, conditions, and policies.
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    To analyze how you interact with the Holly Pennies Platform
                    and provide, maintain and improve the content and
                    functionality of the Holly Pennies Platform (please see the
                    “Cookies” section above to learn how we use cookies).
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    To prevent fraud, criminal activity, or misuses of the Holly
                    Pennies Platform, block prohibited reseller traffic, and to
                    ensure the security of our IT systems, architecture and
                    networks and To comply with legal obligations and legal
                    process and to protect our rights, privacy, safety or
                    property, and/or that of our affiliates, you or other third
                    parties.
                  </Typography>
                </Box>
              </li>
            </ul>
          </li>
          <li>
            <Box py={2}>
              <Typography color={"inherit"} variant="body1">
                Marketing
              </Typography>
            </Box>
            <Typography color={"inherit"} variant="body1">
              We may use your Personal Data to contact you to tell you about
              services we believe will be of interest to you. For instance, if
              you elect to provide your email or telephone number, we may use
              that information to send you special offers relating to the Holly
              Pennies Platform. You may opt out of receiving these emails by
              following the instructions contained in each promotional email we
              send you. You can also control the marketing messages you receive
              by updating your settings through your account. In addition, if at
              any time you do not wish to receive future marketing
              communications, please contact us at support@iotric.com . If you
              unsubscribe from our marketing lists, you will no longer receive
              marketing communications but we will continue to contact you
              regarding the Holly Pennies Platform and to respond to your
              requests.
            </Typography>
          </li>
          <li>
            <Box py={2}>
              <Typography color={"inherit"} variant="body1">
                How We Share Your Information. In certain circumstances we may
                share your Personal Data with third parties without further
                notice to you, unless required by the law, as set forth below:
              </Typography>
            </Box>
            <ul>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    Vendors and Service Providers: To assist us in meeting
                    business operations needs and to perform certain services
                    and functions, we may share Personal Data with providers of
                    hosting, development, payment processing, and analytics
                    services (for more details on the third parties that place
                    cookies through the Holly Pennies Platform, please see the
                    “Cookies” section above). Pursuant to our instructions,
                    these parties will access, process or store Personal Data in
                    the course of performing their duties to us. We take
                    commercially reasonable steps to ensure our service
                    providers adhere to the Security standards we apply to your
                    Personal Data.
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    Business Transfers: If we are involved in a merger,
                    acquisition, financing due diligence, reorganization,
                    bankruptcy, receivership, sale of all or a portion of our
                    assets, or transition of service to another provider, your
                    Personal Data and other information may be shared in the
                    diligence process with counter parties and others assisting
                    with the transaction and transferred to a successor or
                    affiliate as part of that transaction along with other
                    assets.
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    Legal Requirements: If required to do so by law or in the
                    good faith belief that such action is necessary to (i)
                    comply with a legal obligation, including to meet national
                    security or law enforcement requirements, (ii) protect and
                    defend our rights or property, (iii) prevent fraud, (iv) act
                    in urgent circumstances to protect the personal safety of
                    users of the Holly Pennies Platform, or the public, or (v)
                    protect against legal liability.
                  </Typography>
                </Box>
              </li>
              <li>
                <Box py={1}>
                  <Typography color={"inherit"} variant="body1">
                    With Your Consent: We will share your Personal Data with
                    your consent or at your direction. We will provide you with
                    information about how your personal data will be shared at
                    the time we obtain your consent.
                  </Typography>
                </Box>
              </li>
            </ul>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Data Retention. We keep Personal Data for as long as reasonably
                necessary for the purposes described in this Privacy Policy,
                while we have a business need to do so, or as required by law
                (e.g. for tax, legal, accounting or other purposes), whichever
                is the longer.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Updating Your Information. Please log in to your account or
                contact us at support@iotric.com if you need to change or
                correct your Personal Data.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Children. The Holly Pennies Platform is not directed to children
                who are under the age of 13. Holly Pennies does not knowingly
                collect Personal Data from children under the age of 13. If you
                have reason to believe that a child under the age of 13 has
                provided Personal Data to Holly Pennies through the Holly
                Pennies Platform, please contact us at support@iotric.com and we
                will try to delete that information from our databases.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Security. You use the Holly Pennies Platform at your own risk.
                We comply with reasonable industry standards to protect Personal
                Data both online and offline from loss, misuse, and unauthorized
                access, disclosure, alteration or destruction. However, no
                Internet or e-mail transmission is ever fully secure or error
                free. In particular, e-mail sent to or from us may not be
                secure. Therefore, you should take special care in deciding what
                information you send to us through the Holly Pennies Platform or
                by e-mail. In addition, we are not responsible for circumvention
                of any privacy settings or security measures contained on the
                Holly Pennies Platform, or third party websites.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Transferring Personal Data to the U.S. Holly Pennies has its
                headquarters in the United States and the Holly Pennies Platform
                is intended for Users located in the United States. The Personal
                Data we collect from you will be processed in the United States,
                and, subject to applicable law, may be transferred to and
                processed in other countries outside the United States. The
                standards and laws that apply to the protection of Personal Data
                in the United States or these countries may be different than
                the ones that are applicable to the country where you are
                located.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Your Choices. Whether or not you provide Personal Data to us is
                your decision, but if you choose not to provide information that
                is needed to use features of the Holly Pennies Platform and/or
                Holly Pennies Services, you may be unable to use those features.
                You can also contact us at support@iotric.com to request access
                to your data or to ask us to update, correct, or delete your
                Personal Data.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Updates to this Privacy Policy. We may change, modify or amend
                this Privacy Policy from time to time. We will notify you of
                material changes to this Privacy Policy by posting the amended
                terms on the Holly Pennies Platform at least thirty (30) days
                before the effective date of the changes. In addition, you will
                be required to affirmatively accept the new Privacy Policy the
                first time you visit the Holly Pennies Platform and log in to
                your member account after the new Privacy Policy takes effect.
                If you do not agree with the proposed changes, you should
                discontinue your use of the Holly Pennies Platform prior to the
                time the new Privacy Policy takes effect. If you continue using
                the Holly Pennies Platform after the new Privacy Policy takes
                effect, you will be bound by the modified Privacy Policy.
              </Typography>
            </Box>
          </li>
          <li>
            <Box py={1}>
              <Typography color={"inherit"} variant="body1">
                Contact Us. If you have any questions about our Privacy Policy
                or the information practices relating to the Holly Pennies
                Platform, please feel free to contact us at support@iotric.com .
              </Typography>
            </Box>
          </li>
        </ol>
      </Container>
    </>
  );
}
