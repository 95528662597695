import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { redirect } from "react-router-dom";


const NonAuthWrapper = ({ children }) => {
  const loadConfigure = () => {
    if (localStorage.getItem('user')) {
      redirect('/')
      console.log("redirect from no auth")
    }
  }

  useEffect(() => {
    loadConfigure()
  }, [])

  return (
    <React.Fragment>
      <Box>
        {children}
      </Box>
    </React.Fragment>
  )
}

export default NonAuthWrapper;