import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default function MaxWindow(props) {
  return (
    <>
      <Dialog
        sx={{
          position: "fixed",
          // zIndex: "12001",
          overflow: "hidden !important",
        }}
        open={props.isOpen}
        onClose={props.toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ p: 4, paddingTop: "1rem !important" }}>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
}
