import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import DataTable from "Common/DataTable/DataTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import { format } from "date-fns";

export default function DonorInvitations() {

  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <DataTable
            title="Donor Invitation List"
            isLoading={false}
            // isError={isError}
            // errorMessage={
            //   q.isError
            //     ? (q.error && q.error?.error && q.error?.error?.message) ||
            //       "Errors occurred while getting donors list! please contact admin."
            //     : null
            // }
            columns={[
              {
                title: "Created Date",
                field: "createdAt",
                render: (rowData) => {
                  return format(new Date(rowData.createdAt), "dd/MM/yyyy");
                },
              },
              { title: "First Name", field: "userInfo.first_name" },
              { title: "Last Name", field: "userInfo.last_name" },
              { title: "Contact No", field: "userInfo.mobile" },
              { title: "Email", field: "userInfo.email" },
            ]}
            data={[]}
          // onReload={() => {
          //   q.refetch();
          // }}
          // actions={[
          //   {
          //     icon: () => {
          //       return <AddBox />;
          //     },
          //     tooltip: "Add Donor",
          //     isFreeAction: true,
          //     onClick: () => {
          //       setIsOpen(true);
          //     },
          //   },
          //   {
          //     icon: () => {
          //       return <Visibility color="info" />;
          //     },
          //     tooltip: "View More",
          //     onClick: (e, rowData) => {
          //       setIsView(true);
          //       setObjItem(rowData);
          //     },
          //   },
          //   {
          //     icon: () => {
          //       return <Edit color="primary" />;
          //     },
          //     tooltip: "Edit Information",
          //     onClick: (e, rowData) => {
          //       setIsEdit(true);
          //       setIsOpen(true);
          //       setObjItem(rowData);
          //     },
          //   },
          //   {
          //     icon: () => {
          //       return <Delete color="error" />;
          //     },
          //     tooltip: "Delete Donor",
          //     onClick: (e, rowData) => {
          //       deleteDonor(rowData.id);
          //     },
          //   },
          // ]}
          />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
