/* eslint-disable react-hooks/exhaustive-deps */

import MainLayouts from "Common/Layouts/MainLayouts";
import IndexPage from "IndexPage";
import Sidebar from "Sidebar";
import HolyHouses from "components/Admin/Routes/Holy-houses/HolyHouses";
import AdminDashboardPage from "pages/Admin/AdminDashboard/AdminDashboardPage";
import AdminLoginPage from "pages/Admin/AdminLogin/AdminLoginPage";
import AdminProfilePage from "pages/Admin/AdminProfile/AdminProfilePage";
import AdminSignUpPage from "pages/Admin/AdminSignUp/AdminSignUpPage";
import AdminUserSection from "pages/Admin/AdminUserSection/AdminUserSection";
import AdminEventSection from "pages/Admin/AllEvents/AdminEventSection";
import AdminDonorSection from "pages/Admin/Donors/AdminDonorSection";
import ForgetPasswordAdmin from "pages/Admin/ForgetPasswordAdmin/ForgetPasswordAdmin";
import AdminHowSection from "pages/Admin/How/AdminHowSection";
import DonorDashboardPage from "pages/Donor/DonorDashboard/DonorDashboardPage";
import DonorDonates from "pages/Donor/DonorDonates";
import DonorHows from "pages/Donor/DonorHows";
import DonorInvitations from "pages/Donor/DonorInvitations";
import DonorInviteSignUp from "pages/Donor/DonorLogin/DonorInviteSignUp";
import DonorLoginPage from "pages/Donor/DonorLogin/DonorLoginPage";
import DonorSignUp from "pages/Donor/DonorLogin/DonorSignUp";
import DonorPayment from "pages/Donor/DonorPayment";
import DonorProfile from "pages/Donor/DonorProfile/DonorProfilePage";
import ForgetPasswordDonor from "pages/Donor/FortgetPasswordDonor/ForgetPasswordDonor";
import ChildHowInviteSignUp from "pages/How/ChildHow/ChildHowInviteSignUp";
import ForgetPasswordHowAdmin from "pages/How/ForgetPassword/ForgetPasswordHowAdmin";
import HowHouseOfWorshipPage from "pages/How/HouseOfWorship/HowHouseOfWorshipPage";
import HowDashboardPage from "pages/How/HowDashbord/HowDashboardPage";
import HowDonorSection from "pages/How/HowDonors/HowDonorSection";
import HowEventPage from "pages/How/HowEvents/HowEventPage";
import HowInviteSignUp from "pages/How/HowInviteSignUp/HowInviteSignUp";
import HowLoginPage from "pages/How/HowLogin/HowLoginPage";
import HowSignUpPage from "pages/How/HowLogin/HowSignUpPage";
import HowProfile from "pages/How/HowProfile/HowProfile";
import HowRevenuePage from "pages/How/HowRevenue/HowRevenuePage";
import HowPaymentSectionPage from "pages/How/HowSetupPayment/HowPaymentSectionPage";
import HowSupportSectionPage from "pages/How/HowSupport/HowSupportSectionPage";
import ResetHowPassword from "pages/How/ResetHowPassword/ResetHowPassword";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import { Route, Routes } from "react-router";
import authService from "services/http-service/AuthService";

// this is the comment
export default function App() {
  const userRole = authService.getUserRole();
  if (!userRole) {
    document.title = "Holydumos dashboard";
  }

  if (userRole === "HowAdmin") {
    document.title = "How admin";
  }

  if (userRole === "Admin") {
    document.title = "Admin";
  }

  if (userRole === "Donor") {
    document.title = "Donor admin";
  }

  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/how" element={<Sidebar />} />
      <Route path="/how-login" element={<HowLoginPage />} />
      <Route path="/donor-login" element={<DonorLoginPage />} />
      <Route path="/admin-login" element={<AdminLoginPage />} />
      <Route path="/how-revenue" element={<HowRevenuePage />} />
      <Route path="/how-events" element={<HowEventPage />} />
      <Route path="/how-dashboard" element={<HowDashboardPage />} />
      <Route path="/how-donors" element={<HowDonorSection />} />
      <Route path="/how-support" element={<HowSupportSectionPage />} />
      <Route path="/how-payments" element={<HowPaymentSectionPage />} />
      <Route path="/donor-dashboard" element={<DonorDashboardPage />} />
      <Route path="/dashboard" element={<AdminDashboardPage />} />
      <Route path="/how-house_of_worship" element={<HowHouseOfWorshipPage />} />
      <Route path="/holy-houses" element={<HolyHouses />} />
      <Route path="/how-profile" element={<HowProfile />} />
      <Route path="/donor-profile" element={<DonorProfile />} />
      <Route path="/admin-profile" element={<AdminProfilePage />} />
      <Route path="/holy-house-account-creation" element={<HowSignUpPage />} />
      <Route
        path="/child-how-account-creation/:token"
        element={<ChildHowInviteSignUp />}
      />
      <Route
        path="/holy-house-account-creation/:token"
        element={<HowInviteSignUp />}
      />
      <Route path="/admin-signup" element={<AdminSignUpPage />} />
      <Route path="/donor-signup" element={<DonorSignUp />} />
      <Route path="/donor-signup/:token" element={<DonorInviteSignUp />} />
      <Route path="/dashboard/holyHouse" element={<AdminHowSection />} />
      <Route path="/payments" element={<DonorPayment />} />
      <Route path="/houseOfWorship" element={<DonorHows />} />
      <Route path="/invitations" element={<DonorInvitations />} />
      <Route path="/donates" element={<DonorDonates />} />
      <Route
        path="/forgetPasswordHowAdmin"
        element={<ForgetPasswordHowAdmin />}
      />
      <Route path="/forgetPasswordDonor" element={<ForgetPasswordDonor />} />
      <Route path="/forgetPasswordAdmin" element={<ForgetPasswordAdmin />} />
      <Route path="/reset-how-password/:token" element={<ResetHowPassword />} />
      <Route
        path="/reset-admin-password/:token"
        element={<ResetHowPassword isAdmin={true} />}
      />
      <Route
        path="/reset-donor-password/:token"
        element={<ResetHowPassword isDonor={true} />}
      />
      <Route path="/dashboard/donors" element={<AdminDonorSection />} />
      <Route path="/dashboard/events" element={<AdminEventSection />} />
      <Route path="/dashboard/adminUsers" element={<AdminUserSection />} />
      <Route
        path="*"
        element={
          authService.isUserLogin() ? (
            <MainLayouts>
              <PageNotFound />
            </MainLayouts>
          ) : (
            <PageNotFound />
          )
        }
      />
    </Routes>
  );
}
