import { Save } from "@mui/icons-material";
import { Box, Card, IconButton, Typography } from "@mui/material";

export default function LayoutHeaders(props) {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ flex: 1, paddingLeft: "2rem" }}>
        <Typography variant="h6" color="inherit">
          {props.title}
        </Typography>
      </Box>

      <IconButton aria-label="save" title="Save layout" onClick={props.onSave}>
        <Save />
      </IconButton>
    </Card>
  );
}
