/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Dialog, DialogContent, DialogTitle, Slide } from "@mui/material";
import MinimizeItemShower from "Common/MinimizeItemShower";
import { forwardRef, useEffect, useState } from "react";
import {
  DonorCommonStore,
  getDonorDashboardLayouts,
  setDonorCardIsChanged,
} from "services/DonorCommonStore";
import authService from "services/http-service/AuthService";

import {
  AdminDashboardLayoutStore,
  getAdminDashboardLayouts,
  setAdminCardLayoutIsChanged,
} from "services/store/GridTemplateLayoutStore/AdminDashboardLayoutStore";

import {
  HowDashboardLayoutStore,
  getDashboardLayoutElement,
  setIsCardLayoutChanged,
} from "services/store/GridTemplateLayoutStore/HowDashboardLayoutStore";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LayoutMenu({ toggleLayoutMenu, isOpen }) {

  const isCardLayoutsChanged = HowDashboardLayoutStore.useState(
    (s) => s.isCardLayoutsChanged
  );

  const isDonorCardChanged = DonorCommonStore.useState(
    (s) => s.donorCardIsChanged
  );

  const isAdminCardLayoutsChanged = AdminDashboardLayoutStore.useState(
    (s) => s.adminCardLayoutIsChanged
  );

  const [lstHowDashboardElement, setLstHowDashboardElement] = useState(null);

  const getData = () => {
    const userType = authService.getUserRole();

    if (userType === "Donor") {
      return getDonorDashboardLayouts();
    }

    if (userType === "Admin") {
      return getAdminDashboardLayouts();
    }

    if (userType === "HowAdmin") {
      return getDashboardLayoutElement();
    }
  };

  const loadData = () => {
    const result = getData();

    const newData = result.filter((item) => {
        return item.isClosed;
    });

    setLstHowDashboardElement(newData);
  };

  
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    loadData();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      isCardLayoutsChanged ||
      isAdminCardLayoutsChanged ||
      isDonorCardChanged
    ) {
      loadData();
      setIsCardLayoutChanged(false);
      setAdminCardLayoutIsChanged(false);
      setDonorCardIsChanged(false);
    }
  }, [isCardLayoutsChanged, isAdminCardLayoutsChanged, isDonorCardChanged]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={toggleLayoutMenu}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "20%",
              textAlign: "center",
              backgroundColor: "#dc3545",
            }}
          >
            <span style={{ color: "black" }}>Closed Items</span>
          </Box>
        </Box>
        To restore the card please click on restore button.
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: "400px",
          overflow: "auto",
        }}
      >
        {lstHowDashboardElement &&
          lstHowDashboardElement.length > 0 &&
          lstHowDashboardElement.map((item, index) => {
            return (
              <div className="newOne" key={index}>
                <MinimizeItemShower name={item.name} cardID={item.key} />
              </div>
            );
          })}
      </DialogContent>
    </Dialog>
  );
}
