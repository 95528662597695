import { Grid, Typography } from "@mui/material";
import HowDonationCard from "./HowDonationCard";

export default function HowDonorRevenue() {
  return (
    <>
      <Typography variant="h5">Donor Revenue</Typography>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <HowDonationCard title="Today Donation" amount="788k" />
        </Grid>
        <Grid item xs={12} md={6}>
          <HowDonationCard title="Current Month Donation" amount="1500k" />
        </Grid>
        <Grid item xs={12} md={6}>
          <HowDonationCard title="Total Donation" amount="451k" />
        </Grid>
      </Grid>
    </>
  );
}
