import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import GetRequiredLabel from "Common/GetRequiredLabel";
import HRoundedTextField from "Common/Inputs/HRoundedTextField";
import { RedditTextField } from "Common/Inputs/Inputs";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import apiService from "services/http-service/ApiService";
import { CommonStore } from "services/store/CommonStore/PullStore";
import { SUPPORTED_FORMATS } from "validations/user.validation";
import * as yup from "yup";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  childHouseName: yup.string().required("Child house name is required"),
  isEdit: yup.boolean(),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Email is required"),
  password: yup.string().when("isEdit", {
    is: true,
    then: yup.string().optional(),
    otherwise: yup.string().required("Password is required"),
  }),
  mobile: yup.string().min(10).max(10).required("Phone number is required"),
  address: yup.string().required("Address field is required"),
  description: yup.string().optional(),
  country: yup.string().required("Country is  required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("Zip code is required"),
  userProfile: yup
    .mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  howLogo: yup
    .mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  howImage: yup
    .mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export default function AddUpdateHowProfile(props) {
  const [showPassword, setShowPassword] = useState(false);
  const isWhiteTheme = CommonStore.useState((s) => s.themeMode);
  const navigate = useNavigate();
  const updateOperation = useMutation(async (data) => {
    return await apiService.updateHowProfile(data);
  });

  const objForm = useForm({
    defaultValues: {
      firstName: props.objUser?.first_name || "",
      lastName: props.objUser?.last_name || "",
      childHouseName: props.objHow.how_name || "",
      email: props.objUser?.email || "",
      password: "",
      isEdit: props.isEdit,
      mobile: props.objUser?.mobile || "",
      address: props.objHow.address || "",
      description: props.objHow.how_desc || "",
      state: props.objHow.state || "",
      city: props.objHow.city || "",
      zipCode: props.objHow.zipcode || "",
      userProfile: null,
      country: props.objHow.country_code || "",
      howLogo: null,
      howImage: null,
    },
    resolver: yupResolver(schema),
  });

  const onSave = async (data) => {
    try {
      const io = {
        id: props.objHow.id,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        mobile: data.mobile,
        how_name: data.childHouseName,
        how_desc: data.description,
        address: data.address,
        country_code: data.country,
        zipcode: data.zipCode,
        state: data.state,
        city: data.city,
      };

      const res = await updateOperation.mutateAsync(io);
      if (res.success) {
        toast.success(res.data);
        if (props.onComplete && props.toggle) {
          props.onComplete();
          props.toggle();
        }
        return null;
      }

      throw new Error(res.message);
    } catch (ex) {
      toast.error("Some error occurred!");
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        fontWeight={"bold"}
        color={isWhiteTheme ? "white" : "black"}
      >
        Update How Profile
        <Divider />
      </Typography>
      <FormProvider {...objForm}>
        <form onSubmit={objForm.handleSubmit(onSave)}>
          <Box sx={{ maxWidth: "100%", py: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <GetRequiredLabel isRequired={true} name={"First Name"} />
                <HRoundedTextField name={"firstName"} label={"First Name"} />
              </Grid>
              <Grid item xs={12} md={4}>
                <GetRequiredLabel name={"Last Name"} isRequired={true} />
                <HRoundedTextField name={"lastName"} label={"Last Name"} />
              </Grid>
              <Grid item xs={12} md={4}>
                <GetRequiredLabel isRequired={true} name={"Child House Name"} />
                <HRoundedTextField
                  name={"childHouseName"}
                  label={"Child House Name"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <GetRequiredLabel isRequired={true} name={"Email"} />
                <HRoundedTextField name={"email"} label={"Email"} />
              </Grid>
              {!props.isEdit && (
                <Grid item xs={12} md={4}>
                  <GetRequiredLabel isRequired={true} name={"Password"} />
                  <Controller
                    name={"password"}
                    control={objForm.control}
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <RedditTextField
                          fullWidth
                          variant="outlined"
                          label="Password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                          error={Boolean(objForm.formState.errors.password)}
                          helperText={
                            objForm.formState.errors.password
                              ? objForm.formState.errors.password.message
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <GetRequiredLabel isRequired={true} name={"Mobile"} />
                <HRoundedTextField name={"mobile"} label={"Mobile"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetRequiredLabel isRequired={true} name={"Address"} />
                <Controller
                  name={"address"}
                  control={objForm.control}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <RedditTextField
                        fullWidth
                        variant="outlined"
                        label="Address"
                        name="address"
                        type="text"
                        {...field}
                        multiline
                        rows={3}
                        error={Boolean(objForm.formState.errors.address)}
                        helperText={
                          objForm.formState.errors.address
                            ? objForm.formState.errors.address.message
                            : ""
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetRequiredLabel isRequired={false} name={"Description"} />
                <Controller
                  name={"description"}
                  control={objForm.control}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <RedditTextField
                        fullWidth
                        variant="outlined"
                        label="Description"
                        name="description"
                        type="text"
                        {...field}
                        multiline
                        rows={3}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <GetRequiredLabel isRequired={true} name={"Country"} />
                <Controller
                  name={"country"}
                  control={objForm.control}
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        {...field}
                        options={[
                          "India",
                          "America",
                          "London",
                          "Shrilanka",
                          "Canada",
                          "USA",
                        ]}
                        onChange={(e, value) => {
                          objForm.setValue("country", value);
                        }}
                        label="Country"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              borderRadius: 15,
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "inherit",
                                  borderRadius: 5,
                                },
                              },
                            }}
                            label="Select Country"
                            {...params}
                            fullWidth
                            variant="outlined"
                            type="text"
                            error={Boolean(objForm.formState.errors.country)}
                            helperText={
                              objForm.formState.errors.country
                                ? objForm.formState.errors.country.message
                                : ""
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <GetRequiredLabel isRequired={true} name={"State"} />
                <HRoundedTextField name={"state"} label={"State"} />
              </Grid>
              <Grid item xs={12} md={3}>
                <GetRequiredLabel isRequired={true} name={"City"} />
                <HRoundedTextField name={"city"} label={"City"} />
              </Grid>
              <Grid item xs={12} md={3}>
                <GetRequiredLabel isRequired={true} name={"Zip Code"} />
                <HRoundedTextField name={"zipCode"} label={"Zip Code"} />
              </Grid>
              {!props.isEdit && (
                <>
                  <Grid item xs={12} md={4}>
                    <GetRequiredLabel
                      isRequired={false}
                      name={"User Profile (jpg,img,png,jpeg)"}
                    />
                    <RedditTextField
                      type="file"
                      name="userProfile"
                      fullWidth
                      id="file"
                      label="Select image for event"
                      onChange={(event) => {
                        objForm.setValue("userProfile", event.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(objForm.formState.errors.userProfile)}
                      helperText={
                        objForm.formState.errors.userProfile
                          ? objForm.formState.errors.userProfile.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <GetRequiredLabel
                      isRequired={false}
                      name={"How Logo (jpg,img,png,jpeg)"}
                    />

                    <RedditTextField
                      type="file"
                      name="howLogo"
                      fullWidth
                      id="file"
                      label="Select how image"
                      onChange={(event) => {
                        objForm.setValue("howLogo", event.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <GetRequiredLabel
                      isRequired={false}
                      name={"How Image (jpg,img,png,jpeg)"}
                    />
                    <RedditTextField
                      type="file"
                      name="howImage"
                      fullWidth
                      id="file"
                      label="Select back image"
                      onChange={(event) =>
                        objForm.setValue("howImage", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </>
              )}
              {updateOperation.isError && (
                <Grid item xs={12} py={2}>
                  <Box py={2}>
                    <ErrorPlaceHolder
                      error={
                        updateOperation.error &&
                        updateOperation.error.error &&
                        updateOperation.error.error.message &&
                        updateOperation.error.error.message.details &&
                        updateOperation.error.error.message.details[0] &&
                        updateOperation.error.error.message.details[0].message
                          ? updateOperation.error.error.message.details[0]
                              .message
                          : updateOperation.error &&
                            updateOperation.error.data &&
                            updateOperation.error.data.error
                          ? updateOperation.error.data.error.message
                          : updateOperation.error.error.message
                      }
                    />
                  </Box>
                </Grid>
              )}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: "100%",
                  padding: 2,
                }}
                justifyContent={"center"}
              >
                <LoadingButton
                  loading={updateOperation.isLoading}
                  disabled={updateOperation.isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {props.isEdit ? "Update" : "Save"}
                </LoadingButton>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (props.toggle) {
                      props.toggle();
                    } else {
                      navigate("/");
                    }
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
