/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";

export default function ViewAdminHowParents(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [objItem, setObjItem] = useState(null);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const res = await apiService.getAdminHowParentInfo(props.parentID);
      if (res.success) {
        setObjItem(res.data[0]);
        return null;
      }

      throw new Error(res.message || res.data);
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <LinearProgress />;
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={"bold"} variant="h6">
            Parent How Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {objItem !== null && typeof objItem === "undefined" && (
            <ErrorPlaceHolder error="Parent How Information Not Available!" />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"} variant="body1">
            Created Date :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {utils.getDDMMYYYY(objItem?.User?.createdAt) || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"} variant="body1">
            First Name :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.User?.first_name || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Last Name :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.User?.last_name || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Description :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.how_desc || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Email Add :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.User?.email || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Contact No :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.User?.mobile || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            City :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{objItem?.city || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            State :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{objItem?.state || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Zip Code :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{objItem?.zipcode || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Country :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.country_code || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Status :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {objItem?.User?.isActive ? "Active" : "Not-Active" || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
